import { createApp } from 'vue';
import App from '@/App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import "simple-keyboard/build/css/index.css";
import '@/css/style.css';
const app = createApp(App)
    .use(store)
    .use(router);
import keyboard from '@/components/keyboard.vue';
app.component('keyboard', keyboard);
import blinker from '@/components/blinker.vue';
app.component('blinker', blinker);
app.mount('#app');
