import store from '@/store';
import { createRouter, createWebHashHistory } from 'vue-router';
const routes = [
    {
        path: '/',
        component: () => import("@/views/layout.vue"),
        redirect: 'partiamo',
        children: [
            {
                path: 'partiamo',
                name: "partiamo",
                component: () => import("@/views/partiamo.vue"),
            },
            {
                path: 'nome',
                name: "nome",
                component: () => import("@/views/nome.vue"),
            },
            {
                path: 'regole',
                component: () => import("@/views/regole.vue"),
            },
            {
                path: 'game',
                name: "game",
                component: () => import("@/views/game.vue"),
            },
            {
                path: 'mispiace',
                component: () => import("@/views/mispiace.vue"),
            },
            {
                path: 'complimenti',
                component: () => import("@/views/complimenti.vue"),
            },
        ]
    }
];
const router = createRouter({
    history: createWebHashHistory(),
    routes
});
router.beforeEach((to, from, next) => {
    if (store.state.beforeNavCbs.length == 0) {
        next();
    }
    store.state.beforeNavCbs.forEach(x => {
        x(to, from, next);
    });
    store.state.beforeNavCbs = [];
});
router.afterEach((to, from) => {
    store.state.afterNavCbs.forEach(x => {
        x(to, from);
    });
    store.state.afterNavCbs = [];
});
export default router;
