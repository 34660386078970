import { createStore } from 'vuex';
import { StorageServices } from '@/services/StorageServices';
let _state = {
    lead: null,
    showSpinner: 0,
    prevFunction: () => { },
    nextFunction: () => { },
    beforeNavCbs: [],
    afterNavCbs: [],
    calcWidth: () => {
        let body = document.body;
        setTimeout(() => {
            let rekt = body.getBoundingClientRect();
            let height = 2208;
            let width = 1420; //1242;
            let newW = width * (rekt.height) / height;
            body.style.width = newW + "px";
        }, 50);
    },
    isTouch: () => {
        return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0));
    }
};
let store = createStore({
    state: _state
});
store.state.lead = StorageServices.getlead();
export default store;
