class _StorageServices {
    readFromLocalStorage(lsName) {
        let data = window.localStorage.getItem(lsName);
        if (data) {
            return JSON.parse(data);
        }
        else {
            return null;
        }
    }
    getlead() {
        return this.readFromLocalStorage('lead');
    }
    setlead(lead) {
        window.localStorage.setItem('lead', JSON.stringify(lead));
    }
}
export let StorageServices = new _StorageServices();
